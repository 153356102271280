body {
    margin: 0;
    font-family: 'Open_Sans';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color: inherit;
    text-decoration: inherit;
}

div {
    box-sizing: border-box;
    user-select: none;
}

input {
    border: 2px solid #ccc;
    padding: 2px;
    border-radius: 4px;
    outline: none;
}

select {
    outline: none;
    font-size: 17px;
    height: 40px;
    background-position: 95% center;
    background-repeat: no-repeat;
    background-image: url("./images/dropDown.svg");
    background-color: rgba(255, 255, 255, 0.6);
    padding-left: 20px;
    border: none;
    border-radius: 10px;
    position: relative;
    width: 40%;
    text-align: center;
    -webkit-appearance: none;
}

option {
    padding: none;
}

input[type=file]::-webkit-file-upload-button {
    display: none;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #00000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #BBBBBB;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #929292;
}

@font-face {
    font-family: 'Open_Sans';
    src: local('Open_Sans'), url(./fonts/open-sans-v17-latin-regular.woff) format('woff');
}

.animated {
/*
    transition: width 1s;
*/
}

.open {
    width: 350px;
}

.close {
    width: 0px;
    display: none;
}

.animated-height {
    transition: height .5s;
}

/* @keyframes slideRight {
  from {
    width: 350px;
  }

  to {
    width: 0px;
  }
}


@keyframes slideLeft {
  from {
    width: 0px;
  }

  to {
    width: 350px;
  }
} */
